$theme-colors: (
  "info": tomato,
  "danger": #ffaa00,
  "primary": #00bbec,
);

$navbar-dark-color: rgba(255, 255, 255, 1);
$navbar-light-color: rgba(64, 64, 64, 1);
$navitem-border-color: #ddd;
$navitem-highlight-color: #00bbec;

$jumbotron-bg-color: #eefaeb;

$font-weight-normal: 400 !default;
$font-weight-base: 400 !important;

:root {
  --font-family-sans-serif: "Source Sans Pro";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;

  --color-blue: #007bff;
  --color-gray-100: #f8f9fa;
  //--color-gray-800: #343a40;
  --color-gray-dark: #343a40;
  --color-hero-bg: #eefaeb;
  --color-text: #212529;
  --color-link: #00bbec;
  --color-link-hover: #007ea0;
  --color-pack-green: #00c872;
  --color-pack-blue: #00bbec;
  --color-pack-purple: #9d62b8;

  --container-max-sm: 540px;
  --container-max-md: 720px;
  --container-max-lg: 960px;
  --container-max-xl: 1140px;
}
