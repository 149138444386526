:root {
  --font-family-sans-serif: "Source Sans Pro";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --color-blue: #007bff;
  --color-gray-100: #f8f9fa;
  --color-gray-dark: #343a40;
  --color-hero-bg: #eefaeb;
  --color-text: #212529;
  --color-link: #00bbec;
  --color-link-hover: #007ea0;
  --color-pack-green: #00c872;
  --color-pack-blue: #00bbec;
  --color-pack-purple: #9d62b8;
  --container-max-sm: 540px;
  --container-max-md: 720px;
  --container-max-lg: 960px;
  --container-max-xl: 1140px;
}

/*
 * Scaling follows tailwind - 0.25rem increments (or 4px, with base size 1rem = 16px)
 * Unable to get Tailwind to work with Sass, tried with Tailwindcss as postcss plugin
 * PureCSS has no such utility
 */
.pt-20 {
  padding-top: 5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-6 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 3rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-9 {
  margin-bottom: 3rem !important;
}

.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.lead {
  font-size: 1.25rem;
}

.container {
  width: 100%;
  max-width: var(--container-max-sm);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 720px) {
  .container {
    max-width: var(--container-max-md);
  }
}
@media (min-width: 960px) {
  .container {
    max-width: var(--container-max-lg);
  }
  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .px-lg-4 {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .px-lg-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
@media (min-width: 1140px) {
  .container {
    max-width: var(--container-max-xl);
  }
}
.img-fluid {
  max-width: 100%;
  height: auto;
}

.text-white {
  color: #fff;
}

body {
  font: 1rem/1.5 var(--font-family-sans-serif) !important;
  font-weight: 400 !important;
  color: var(--color-text);
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--color-link);
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
}

.block {
  display: block;
}

:not(.navbar-brand) > img {
  max-width: 100%;
}

figure {
  margin: 0;
}

.navbar-toggler {
  border: none !important;
}

.hero-gradient {
  background-image: linear-gradient(135deg, rgb(177, 233, 230) 0%, rgb(177, 233, 230) 0%, rgb(58, 189, 241) 100%, rgb(58, 189, 241) 100%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  padding-top: 5rem !important;
}

:not(.home-page-hero, .bg-eefaeb).jumbotron:first-child {
  background-color: #fff !important;
  padding-top: 5rem !important;
  border-bottom: 1px solid #ccc;
}
:not(.home-page-hero, .bg-eefaeb).jumbotron:first-child .navbar-nav .nav-link {
  color: #000 !important;
}

.jumbotron.bg-eefaeb {
  background-color: #eefaeb !important;
  border-bottom: none;
}

.subscribe-form {
  background-image: linear-gradient(135deg, rgb(177, 233, 230) 0%, rgb(177, 233, 230) 0%, rgb(58, 189, 241) 100%, rgb(58, 189, 241) 100%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  padding: 2.5rem 1rem 3rem 1rem;
}
.subscribe-form .form-group > div {
  padding: 0 4px;
}
.subscribe-form .formkit-input {
  border-radius: 4px;
  color: #888;
  border: 1px solid #ccc;
  font-weight: 400;
  width: 100%;
  padding: 10px 1rem;
}
.subscribe-form .pill-button {
  width: 100%;
  color: rgb(255, 255, 255);
  background-color: #000;
  border-radius: 4px;
  border: none;
  font-weight: 400;
}

.modal-dark {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  background-color: #000;
}

.modal-dark-content {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 560px;
  margin: 0 auto 5%;
}
.modal-dark-content .header {
  padding: 1rem 1.5rem;
  background-color: #eee;
}
.modal-dark-content .body {
  padding: 1.5rem 1.5rem;
}
.modal-dark-content a.navbar-brand {
  margin: 0 0 0 -5px;
  display: block;
  width: 220px;
}
.modal-dark-content a.navbar-brand img {
  max-width: 100%;
  height: auto;
  display: block;
}
.modal-dark-content h1 {
  font-size: 1.725rem;
  font-weight: 500;
}
.modal-dark-content p {
  font-size: 1rem;
  margin: 0.5em 0;
  color: #666;
}
.modal-dark-content .body p:last-child {
  margin-bottom: 0;
}
.modal-dark-content a {
  color: #005f55;
  display: inline-flex;
  align-items: center;
  margin-top: 1.45rem;
  font-size: 0.95rem;
}
.modal-dark-content a svg {
  margin-right: 0.5rem;
}

.price-block {
  border: 3px solid #000;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 0 0 1rem;
  text-align: center;
  position: relative;
}
.price-block .pill-button {
  padding: 1rem 2.5rem !important;
  margin: 0 auto;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.price-block header {
  color: #fff;
  text-transform: uppercase;
  padding: 32px;
}
.price-block h6 {
  font-size: 0.9em;
  letter-spacing: 1px;
  margin: 0 0 5px;
}
.price-block .divide {
  background-color: #fff !important;
  max-width: 50%;
  width: 50px;
  height: 2px;
  margin: 30px auto;
}
.price-block .price {
  font-size: 3rem;
  line-height: 3rem;
  white-space: nowrap;
  margin: 0;
  color: #fff;
  font-weight: 700;
  text-align: center;
}
.price-block .marker {
  background-color: #000 !important;
  color: #fff;
  position: absolute;
  text-transform: uppercase;
  top: 10px;
  right: 10px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 12px;
  padding: 4px 6px;
}
.price-block .feature:nth-child(odd) {
  background-color: #f2f2f2;
}
.price-block .feature {
  padding: 1rem;
}

.price-block.free-pack {
  border-color: var(--color-pack-green);
}
.price-block.free-pack .pill-button,
.price-block.free-pack header {
  background-color: var(--color-pack-green);
}

.price-block.essential-pack {
  border-color: var(--color-pack-purple);
}
.price-block.essential-pack .pill-button,
.price-block.essential-pack header {
  background-color: var(--color-pack-purple);
}

.price-block.ultimate-pack {
  border-color: var(--color-pack-blue);
}
.price-block.ultimate-pack .pill-button,
.price-block.ultimate-pack header {
  background-color: var(--color-pack-blue);
}

button:focus {
  border: none !important;
  box-shadow: none !important;
}

.pill-button:hover,
.pill-button:focus,
.pill-button:active {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
}

.pill-button.btn-light:hover,
.pill-button.btn-light:focus,
.pill-button.btn-light:active,
:not(#top-nav) .pill-button.btn-light:focus,
:not(#top-nav) .pill-button.btn-light:active,
:not(#top-nav) .pill-button.btn-light:hover {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
}

a:focus,
a:active,
a:visited,
button:focus {
  outline: none !important;
}

.dropdown-menu {
  padding: 0;
}
.dropdown-menu a:not(:last-child) {
  border-bottom: 1px solid #ddd;
}

.dropdown, .dropdown.show {
  transition: display 1s ease-in-out 1s, height 1s ease-in-out 1s, opacity 1s ease-in-out 1s !important;
  padding: 0;
}
.dropdown .show:not(.dropdown-menu) {
  margin: 0px;
  border: none;
  padding-left: 0;
  margin-left: 0;
}
.dropdown .show:not(.dropdown-menu) a {
  padding-left: 2rem;
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: color 0.25s linear !important;
}
.dropdown .show:not(.dropdown-menu) a:hover {
  color: #00bbec;
  transition: color 0.25s linear !important;
}

button {
  transition: background-color 0.3s linear !important;
}

@media (max-width: 991.98px) {
  .dropdown,
  .dropdown-menu {
    background-color: transparent !important;
  }
  .dropdown, .dropdown.show,
  .dropdown-menu,
  .dropdown-menu.show {
    transition: display 1s ease-in-out 1s, height 1s ease-in-out 1s, opacity 1s ease-in-out 1s !important;
    padding: 0;
  }
  .dropdown .show,
  .dropdown-menu .show {
    margin: 0px;
    border: none;
    padding-left: 0;
    margin-left: 0;
  }
  .dropdown .show a,
  .dropdown-menu .show a {
    padding-left: 2rem;
    background-color: transparent !important;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
    transition: color 0.25s linear !important;
  }
  .dropdown .show a:hover,
  .dropdown-menu .show a:hover {
    color: #00bbec;
    transition: color 0.25s linear !important;
  }
}
#top-nav.expanded a.nav-link {
  border-bottom: 1px solid #ddd;
  padding-top: 20px;
  padding-bottom: 20px;
}
#top-nav.expanded a.nav-link:hover {
  color: #00bbec;
  transition: color 0.25s linear !important;
}
#top-nav.expanded .btn-primary,
#top-nav.expanded .btn-primary:focus {
  color: #333 !important;
  background-color: transparent;
  border-color: #fff;
  margin: 0;
  padding-left: 0 !important;
  border-bottom: 0 !important;
  border-radius: 0 !important;
}

.pill-button.btn-light,
:not(#top-nav) .pill-button.btn-light {
  color: #333 !important;
  border-radius: 3em;
}

nav.fixed-top.expanded, nav.fixed-top.scrolled,
nav.sticky-top.expanded,
nav.sticky-top.scrolled {
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3) !important;
}

nav.expanded {
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3);
}

nav > div {
  border: none !important;
}

:not(#top-nav) .btn-xl {
  padding: 16px 48px;
  border-radius: 3rem;
  line-height: 1.5;
  font-size: 1.25rem;
}
@media (max-width: 991.98px) {
  :not(#top-nav) .btn-xl {
    font-size: 1rem !important;
  }
}

.hero-gradient .btn-xl,
.product-hero .btn-xl {
  width: 300px;
}
@media (max-width: 991.98px) {
  .hero-gradient .btn-xl,
  .product-hero .btn-xl {
    max-width: 90% !important;
    width: 90%;
  }
}

.file-format h3 {
  font-size: 1rem;
  text-transform: uppercase;
  color: #00c872;
  margin: 0 0 0.5em;
}
.file-format p {
  margin-top: 0;
}
.file-format img {
  margin-left: -10px;
}

.product .features-list {
  padding: 0;
}
.product .features-list li {
  margin-left: 1rem;
  padding-left: 0;
}
.product .price span {
  font-weight: 400;
  border-bottom: 4px solid #f00;
  padding-bottom: 0.5rem;
}

.contact .masthead + article > div {
  margin-top: 2rem;
}

picture.hero-image img {
  width: 1140px;
  max-width: 1140px;
}
@media (max-width: 991.98px) {
  picture.hero-image img {
    max-width: 100% !important;
  }
}

@media (min-width: 768px) and (max-width: 1280px) {
  picture.hero-image img {
    width: 768px;
  }
}
/*
.expanded > .navbar-collapse,
.show > .dropdown-menu {
  $ddAnimationName: ddSlideOpen;
  $ddDuration: 1s;
  $ddIterations: 1;
  $ddTimingFunc: ease;
  $ddFillMode: forwards;

  animation-name: $ddAnimationName;
  animation-duration: $ddDuration;
  animation-iteration-count: $ddIterations;
  animation-timing-function: $ddTimingFunc;
  animation-fill-mode: $ddFillMode;

  -webkit-animation-name: $ddAnimationName;
  -webkit-animation-duration: $ddDuration;
  -webkit-animation-iteration-count: $ddIterations;
  -webkit-animation-timing-function: $ddTimingFunc;
  -webkit-animation-fill-mode: $ddFillMode;

  -moz-animation-name: $ddAnimationName;
  -moz-animation-duration: $ddDuration;
  -moz-animation-iteration-count: $ddIterations;
  -moz-animation-timing-function: $ddTimingFunc;
  -moz-animation-fill-mode: $ddFillMode;
}

@keyframes ddSlideClosed {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes ddSlideOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ddSlideOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
*/
h1,
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

.features-list {
  margin-top: 0;
  padding-left: 0;
}

.center-self {
  display: flex !important;
  margin: 0 auto;
  width: max-content;
}

.disp {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@media (max-width: 768px) {
  .col {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .navbar-brand {
    width: 50px !important;
    overflow: hidden;
  }
  .navbar-brand img {
    width: 250px !important;
  }
}
.privacy-notice h2,
.privacy-notice h3,
.privacy-notice h4,
.privacy-notice ul li {
  margin-bottom: 1rem;
}

form[name=humanistavatars-contact] textarea {
  resize: vertical;
}
form[name=humanistavatars-contact] label {
  display: block;
  font-size: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
form[name=humanistavatars-contact] textarea,
form[name=humanistavatars-contact] input[type=text],
form[name=humanistavatars-contact] input[type=email] {
  background-color: #f5f8fa;
  display: inline-block;
  width: 90%;
  max-width: 500px;
  padding: 9px 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  box-sizing: border-box;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
}
form[name=humanistavatars-contact] input[type=text],
form[name=humanistavatars-contact] input[type=email] {
  height: 40px;
}
form[name=humanistavatars-contact] button {
  margin: 1.5rem 0;
}

label span.error {
  color: red;
  padding-left: 10px;
}

.about img:not(.logo) {
  width: 100% !important;
}

.icons-grid img {
  width: 100%;
}

svg.icon {
  width: 1em;
  height: 1em;
}

[class*=-before] svg.icon {
  margin-left: 0.25em;
  margin-right: 0.75em;
}

[class*=-after] svg.icon {
  margin-left: 0.75em;
}

.feature-card {
  padding: 0 8px;
}

.product-hero .inner {
  padding: 0.75rem 1.5rem;
}
.product-hero h3 {
  font-size: 1.5rem;
}
.product-hero .pill-button {
  margin: 0 auto;
}

@media (min-width: 568px) {
  .product-hero .inner {
    padding: 4rem 1.5rem;
  }
}
.icon-pack-grid > figure {
  width: 33.333333%;
  padding: 0.5rem;
  text-align: center;
}
.icon-pack-grid img {
  max-width: 128px;
  width: 100%;
  height: auto;
}

@media (min-width: 480px) {
  .file-format img {
    display: block;
    margin: 0 auto;
  }
}
@media (min-width: 568px) {
  .subscribe-form .form-group {
    gap: 0;
  }
  .subscribe-form .formkit-field {
    padding-right: 1rem;
  }
  .icon-pack-grid > figure {
    width: 25%;
  }
}
@media (min-width: 768px) {
  .icon-pack-grid > figure {
    width: 20%;
  }
}
@media (min-width: 1024px) {
  .icon-pack-grid > figure {
    width: 16.6667%;
  }
  .product-hero .pill-button {
    margin: unset;
  }
}
.pill-button {
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--color-blue);
  transition: background-color 0.3s linear !important;
  text-decoration: none;
  border: none !important;
}
.pill-button:hover {
  background-color: #000;
  text-decoration: none;
}

:not(.masthead),
#top-nav:not(.expanded) {
  transition: background-color 0.3s linear;
}
:not(.masthead) .pill-button,
#top-nav:not(.expanded) .pill-button {
  color: #fff !important;
  border-radius: 3em;
  border: none !important;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 3rem;
  border-radius: 3em !important;
}
:not(.masthead) .pill-button.btn-dark,
#top-nav:not(.expanded) .pill-button.btn-dark {
  color: #fff !important;
}

.btn-xl {
  padding: 1rem 3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-light {
  color: #212529;
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-100);
}

.btn-dark {
  color: #fff;
  background-color: var(--color-gray-dark);
  border-color: var(--color-gray-dark);
}

.btn :focus,
button :focus,
.button :focus,
.btn-light :focus,
.btn-dark :focus {
  border: none !important;
  box-shadow: none !important;
}

.btn,
button,
.button {
  font-weight: 400;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none !important;
}

.masthead {
  position: fixed;
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: transparent;
  transition: background-color 0.5s linear;
}

.masthead.menu-expanded {
  background-color: #fff;
}

.masthead.scrolled {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3) !important;
}

.navbar-brand {
  margin: 5px 0;
}

.no-banner .masthead {
  border-bottom: 1px solid #ccc;
}

.main-navbar {
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -200vw;
  right: auto;
  opacity: 0;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 77px);
  max-width: 100%;
  transition: opacity 0.25s linear;
}
.main-navbar ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  inset: 0 0 auto 0;
  background-color: #fff;
  align-items: center;
  gap: 0;
  margin: 0;
}
.main-navbar a {
  display: flex;
  text-decoration: none;
  color: #000;
  padding: 8px;
  white-space: nowrap;
}
.main-navbar .pill-button {
  margin-left: 2rem;
  border-radius: 0;
  background-color: transparent !important;
}
.main-navbar .pill-button a {
  padding: 0;
}
.main-navbar li {
  margin: 0;
}
.main-navbar .has-children {
  position: relative;
}
.main-navbar .has-children a {
  display: flex;
  align-items: center;
  gap: 0 4px;
}
.main-navbar .has-children > a::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #000;
  transition: transform 0.15s linear 0.2s;
}
.main-navbar a[aria-expanded=true]::after {
  transform: scaleY(-1);
}
.main-navbar a[aria-expanded=true] + .has-parent {
  height: auto;
  opacity: 1;
}
.main-navbar a[aria-expanded=true] + .has-parent a {
  padding-left: 2rem;
}
.main-navbar a[aria-expanded=true] + .has-parent li {
  border: 0;
}
.main-navbar .has-parent {
  display: flex;
  height: 0;
  opacity: 0;
  flex-flow: column;
  min-width: 10rem;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  overflow: hidden;
  transition: opacity 0.35s ease-in-out !important;
}
.main-navbar .has-parent li {
  width: 100%;
}
.main-navbar a[aria-expanded=false] + .has-parent {
  opacity: 0;
  left: -999999px;
  transition: opacity 0.35s ease-in-out !important;
}
.main-navbar a[aria-expanded=true] + .has-parent {
  opacity: 1;
  left: auto;
}
.main-navbar .has-parent a {
  padding: 5px 24px;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s linear;
}
.main-navbar .has-parent a:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.main-navbar .has-parent > li:last-child a {
  border: 0;
}
.main-navbar .pill-button {
  background-color: #007bff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 9px;
  padding: 0.5rem 1.5rem !important;
  margin-left: 1.5rem;
}
.main-navbar .pill-button a {
  color: #fff;
}

.prepend-dollar-sign::before {
  content: "";
  display: block;
  background: url(../icons/dollar-sign.svg) no-repeat center;
  width: 8px;
  height: 14px;
}

.navbar-toggler {
  background-color: transparent;
}
.navbar-toggler svg path {
  stroke: var(--color-text);
}

.home .navbar-toggler svg path {
  stroke: #fff;
}

.scrolled .navbar-toggler svg path,
.menu-expanded .navbar-toggler svg path {
  stroke: #404040;
}

.menu-expanded .main-navbar {
  left: 0;
  opacity: 1;
  padding: 0 1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.0666666667);
}
.menu-expanded .main-navbar li {
  box-sizing: border-box;
  width: 100%;
  margin: 0 1rem;
  border-bottom: 1px solid #ddd;
}
.menu-expanded .main-navbar ul a {
  padding: 20px 0;
}
.menu-expanded .main-navbar .pill-button {
  padding: 0 !important;
  justify-content: flex-start;
}
.menu-expanded .main-navbar .pill-button.prepend-dollar-sign::before {
  filter: invert(100%);
}
.menu-expanded .main-navbar .pill-button a {
  color: initial;
}

@media (min-width: 992px) {
  .main-navbar {
    position: static;
    opacity: 1;
    width: auto;
    overflow: visible;
    background-color: unset;
  }
  .main-navbar ul {
    flex-direction: row;
    background-color: unset;
  }
  .main-navbar li {
    border-bottom: 0;
  }
  .main-navbar ul a {
    padding: 8px;
  }
  .main-navbar .has-parent {
    position: absolute;
    top: 100%;
    right: auto;
    z-index: 1;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .main-navbar .pill-button {
    border-radius: 50px;
    background-color: var(--color-blue) !important;
  }
  .navbar-toggler {
    display: none;
    inset: auto auto;
  }
}
.homepage-hero {
  background-color: #86d9ea;
  background-image: linear-gradient(135deg, #b1e9e6, #b1e9e6 0, #3abdf1 100%, #3abdf1 0);
}
.homepage-hero .pill-button {
  max-width: 300px;
  width: 90%;
}

.about-hero img {
  max-width: 100%;
}

.jumbotron {
  border-radius: 0 !important;
  padding: 0 15px;
  background-color: var(--color-hero-bg);
}

.jumbotron.minimal {
  min-height: 78px;
}

.hero-action-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem 1rem;
}
.hero-action-row a {
  margin: 0;
}

@media (min-width: 1024px) {
  .hero-action-row {
    gap: 1.5rem 2rem;
  }
}
form[name=humanistavatars-contact] textarea {
  resize: vertical;
}
form[name=humanistavatars-contact] label {
  display: block;
  font-size: 0.8rem;
  margin-top: 2rem;
}
form[name=humanistavatars-contact] textarea,
form[name=humanistavatars-contact] input {
  background-color: #f5f8fa;
  display: inline-block;
  width: 90%;
  max-width: 500px;
  padding: 9px 10px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  color: #33475b;
  border: 1px solid #cbd6e2;
  box-sizing: border-box;
  border-radius: 3px;
}
form[name=humanistavatars-contact] input {
  height: 40px;
}
form[name=humanistavatars-contact] button {
  margin: 1.5rem 0;
}

label span.error {
  color: red;
  padding-left: 10px;
}

.page-footer {
  padding: 1.5rem;
  margin-top: 0 !important;
  background-color: var(--color-gray-dark);
}
.page-footer a {
  color: #00bbec;
}
.page-footer a:hover {
  text-decoration: none;
  color: #007ea0;
}
.page-footer nav {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.page-footer .nav-link {
  display: block;
  padding: 0.5rem;
}

.footer-copyright {
  color: #777;
  margin-top: 1rem;
}

.to-top {
  opacity: 0;
  position: fixed;
  inset: auto 1rem 0.5rem auto;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222;
  background-size: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 50%;
  color: transparent;
  user-select: none;
  cursor: pointer;
  transition-delay: 0s, 0.25s !important;
  transition-property: opacity, inset !important;
  transition-duration: 0.25s !important;
}
.to-top:hover {
  border-color: rgba(255, 255, 255, 0.2);
  background-color: #000;
}
.to-top.show {
  left: auto;
  opacity: 0.5;
  transition-delay: 0.25s, 0s !important;
  transition-property: opacity, inset !important;
  transition-duration: 0.25s !important;
}
.to-top:active {
  opacity: 1;
}

@media (min-width: 768px) {
  .page-footer .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }
  .to-top {
    inset: auto 150vw 2.5rem auto;
  }
  .to-top.show {
    right: 1.5rem;
  }
}
/*# sourceMappingURL=styles.css.map */