body {
  font: 1rem/1.5 var(--font-family-sans-serif) !important;
  font-weight: 400 !important;
  color: var(--color-text);
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: var(--color-link);
}

a:hover {
  text-decoration: underline;
  color: var(--color-link-hover);
}

.block {
  display: block;
}

:not(.navbar-brand) > img {
  max-width: 100%;
}

figure {
  margin: 0;
}

.navbar-toggler {
  border: none !important;
}

@mixin gradient() {
  background-image: linear-gradient(
    135deg,
    rgb(177, 233, 230) 0%,
    rgb(177, 233, 230) 0%,
    rgb(58, 189, 241) 100%,
    rgb(58, 189, 241) 100%
  );
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
}

.hero-gradient {
  @include gradient();

  padding-top: 5rem !important;
}

:not(.home-page-hero, .bg-eefaeb).jumbotron:first-child {
  background-color: #fff !important;

  padding-top: 5rem !important;

  .navbar-nav .nav-link {
    color: #000 !important;
  }

  border-bottom: 1px solid #ccc;
}

.jumbotron.bg-eefaeb {
  background-color: #eefaeb !important;
  border-bottom: none;
}

.subscribe-form {
  @include gradient();

  padding: 2.5rem 1rem 3rem 1rem;

  .form-group > div {
    padding: 0 4px;
  }

  .formkit-input {
    border-radius: 4px;
    color: #888;
    border: 1px solid #ccc;
    font-weight: 400;
    width: 100%;
    padding: 10px 1rem;
  }

  .pill-button {
    width: 100%;
    color: rgb(255, 255, 255);
    background-color: #000;
    border-radius: 4px;
    border: none;
    font-weight: 400;
  }

}

.modal-dark {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  background-color: #000;
}

.modal-dark-content {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 560px;
  margin: 0 auto 5%;

  .header {
    padding: 1rem 1.5rem;
    background-color: #eee;
  }

  .body {
    padding: 1.5rem 1.5rem;
  }

  a.navbar-brand {
    margin: 0 0 0 -5px;
    display: block;
    width: 220px;

    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }

  h1 {
    font-size: 1.725rem;
    font-weight: 500;
  }

  p {
     font-size: 1rem;
     margin: 0.5em 0;
     color: #666;
  }

  .body p:last-child {
    margin-bottom: 0;
  }

  a {
    color: #005f55;
    display: inline-flex;
    align-items: center;
    margin-top: 1.45rem;
    font-size: 0.95rem;

    svg {
      margin-right: 0.5rem;
    }
  }
}


.price-block {
  border: 3px solid #000;
  width: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 0 0 1rem; 
  text-align: center;
  position: relative;

  .pill-button {
    padding: 1rem 2.5rem !important;
    margin: 0 auto;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  header {
    color: #fff;
    text-transform: uppercase;
    padding: 32px;
  }

  h6 {
    font-size: .9em;
    letter-spacing: 1px;
    margin: 0 0 5px;
  }

  .divide {
    background-color: #fff !important;
    max-width: 50%;
    width: 50px;
    height: 2px;
    margin: 30px auto;
  }

  .price {
    font-size: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    margin: 0;
    color: #fff;
    font-weight: 700;
    text-align: center;
  }

  .marker {
    background-color: #000 !important;
    color: #fff;
    position: absolute;
    text-transform: uppercase;
    top: 10px;
    right: 10px;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 12px;
    padding: 4px 6px;
  }
  
  .feature:nth-child(odd) {
    background-color: #f2f2f2;
  }

  .feature {
    padding: 1rem;
  }
}

.price-block.free-pack {
  border-color: var(--color-pack-green);

  .pill-button,
  header {
    background-color: var(--color-pack-green);
  }
}

.price-block.essential-pack {
  border-color: var(--color-pack-purple);

  .pill-button,
  header {
    background-color: var(--color-pack-purple);
  }

}

.price-block.ultimate-pack {
  border-color: var(--color-pack-blue);

  .pill-button,
  header {
    background-color: var(--color-pack-blue);
  }
}

button:focus {
  border: none !important;
  box-shadow: none !important;
}

.pill-button:hover,
.pill-button:focus,
.pill-button:active {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
}

.pill-button.btn-light:hover,
.pill-button.btn-light:focus,
.pill-button.btn-light:active,
:not(#top-nav) .pill-button.btn-light:focus,
:not(#top-nav) .pill-button.btn-light:active,
:not(#top-nav) .pill-button.btn-light:hover {
  background-color: #000 !important;
  color: #fff !important;
  border: none !important;
}

a:focus,
a:active,
a:visited,
button:focus {
  outline: none !important;
}

.dropdown-menu {
  padding: 0;

  a:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }
}

.dropdown {
  &,
  &.show {
    transition: display 1s ease-in-out 1s, height 1s ease-in-out 1s,
      opacity 1s ease-in-out 1s !important;
    padding: 0;
  }

  .show:not(.dropdown-menu) {
    margin: 0px;
    border: none;
    padding-left: 0;
    margin-left: 0;

    a {
      padding-left: 2rem;
      border-bottom: 1px solid $navitem-border-color;
      padding-top: 20px;
      padding-bottom: 20px;
      transition: color 0.25s linear !important;
    }

    a:hover {
      color: $navitem-highlight-color;
      transition: color 0.25s linear !important;
    }
  }
}

button {
  transition: background-color 0.3s linear !important;
}

@include media-breakpoint-between(xs, md) {
  .dropdown,
  .dropdown-menu {
    background-color: transparent !important;

    &,
    &.show {
      transition: display 1s ease-in-out 1s, height 1s ease-in-out 1s,
        opacity 1s ease-in-out 1s !important;
      padding: 0;
    }

    .show {
      margin: 0px;
      border: none;
      padding-left: 0;
      margin-left: 0;

      a {
        padding-left: 2rem;
        background-color: transparent !important;
        border-bottom: 1px solid $navitem-border-color;
        padding-top: 20px;
        padding-bottom: 20px;
        transition: color 0.25s linear !important;
      }

      a:hover {
        color: $navitem-highlight-color;
        transition: color 0.25s linear !important;
      }
    }
  }
}

#top-nav.expanded {
  a.nav-link {
    border-bottom: 1px solid $navitem-border-color;
    padding-top: 20px;
    padding-bottom: 20px;

    &:hover {
      color: $navitem-highlight-color;
      transition: color 0.25s linear !important;
    }
  }

  .btn-primary,
  .btn-primary:focus {
    color: #333 !important;
    background-color: transparent;
    border-color: #fff;
    margin: 0;
    padding-left: 0 !important;
    border-bottom: 0 !important;
    border-radius: 0 !important;
  }
}

.pill-button.btn-light,
:not(#top-nav) .pill-button.btn-light {
  color: #333 !important;
  border-radius: 3em;
}

nav.fixed-top,
nav.sticky-top {
  &.expanded,
  &.scrolled {
    box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3) !important;
  }
}

nav.expanded {
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3);
}

nav > div {
  border: none !important;
}

:not(#top-nav) {
  .btn-xl {
    padding: 16px 48px;
    border-radius: 3rem;
    line-height: 1.5;
    font-size: 1.25rem;

    // @include button-size(
    //   $btn-padding-y-lg * 2,
    //   $btn-padding-y-lg * 6,
    //   $btn-font-size-lg,
    //   $btn-line-height-lg,
    //   $btn-border-radius-lg
    // );
  }

  @include media-breakpoint-between(xs, md) {
    .btn-xl {
      font-size: 1rem !important;
    }
  }
}

.hero-gradient .btn-xl,
.product-hero .btn-xl {
  width: 300px;

  @include media-breakpoint-between(xs, md) {
    max-width: 90% !important;
    width: 90%;
  }
}

.file-format {
  h3 {
    font-size: 1rem;
    text-transform: uppercase;
    color: #00c872;
    margin: 0 0 0.5em;
  }

  p {
    margin-top: 0;
  }

  img {
    margin-left: -10px;
  }
}

.product {
  .features-list {
    padding: 0;

    li {
      margin-left: 1rem;
      padding-left: 0;
    }
  }

  .price span {
    font-weight: 400;
    border-bottom: 4px solid #f00;
    padding-bottom: 0.5rem;
  }
}

.contact {
  .masthead + article > div {
    margin-top: 2rem;
  }
}



// .btn-md {
//   @include button-size(
//     $btn-padding-y-lg,
//     $btn-padding-y-lg * 2,
//     $btn-font-size,
//     $btn-line-height,
//     3rem
//   );
// }

picture.hero-image img {
  width: 1140px;
  max-width: 1140px;

  @include media-breakpoint-between(xs, md) {
    max-width: 100% !important;
  }
}

@media (min-width: 768px) AND (max-width: 1280px) {
  picture.hero-image img {
    width: 768px;
  }
}

/*
.expanded > .navbar-collapse,
.show > .dropdown-menu {
  $ddAnimationName: ddSlideOpen;
  $ddDuration: 1s;
  $ddIterations: 1;
  $ddTimingFunc: ease;
  $ddFillMode: forwards;

  animation-name: $ddAnimationName;
  animation-duration: $ddDuration;
  animation-iteration-count: $ddIterations;
  animation-timing-function: $ddTimingFunc;
  animation-fill-mode: $ddFillMode;

  -webkit-animation-name: $ddAnimationName;
  -webkit-animation-duration: $ddDuration;
  -webkit-animation-iteration-count: $ddIterations;
  -webkit-animation-timing-function: $ddTimingFunc;
  -webkit-animation-fill-mode: $ddFillMode;

  -moz-animation-name: $ddAnimationName;
  -moz-animation-duration: $ddDuration;
  -moz-animation-iteration-count: $ddIterations;
  -moz-animation-timing-function: $ddTimingFunc;
  -moz-animation-fill-mode: $ddFillMode;
}

@keyframes ddSlideClosed {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes ddSlideOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes ddSlideOpen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
*/

h1,
h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
}

h4 {
  font-size: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

address,
dl,
ol,
ul {
  margin-bottom: 1rem;
}

.features-list {
  margin-top: 0;
  padding-left: 0;
}

.center-self {
  display: flex !important;
  margin: 0 auto;
  width: max-content;
}

.disp {
  position: absolute; top: -9999px; left: -9999px;
}


@media (max-width: 768px) {
  // h1,
  // .h1 {
  //   font-size: 2rem;
  // }

  .col {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .navbar-brand {
    width: 50px !important;
    overflow: hidden;

    img {
      width: 250px !important;
    }
  }
}

.privacy-notice h2,
.privacy-notice h3,
.privacy-notice h4,
.privacy-notice ul li {
  margin-bottom: 1rem;
}

form[name="humanistavatars-contact"] {
  textarea {
    resize: vertical;
  }

  label {
    display: block;
    font-size: 0.8rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }

  textarea,
  input[type="text"],
  input[type="email"] {
    background-color: #f5f8fa;
    display: inline-block;
    width: 90%;
    max-width: 500px;
    padding: 9px 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }

  input[type="text"],
  input[type="email"] {
    height: 40px;
  }

  button {
    margin: 1.5rem 0;
  }
}

label span.error {
  color: red;
  padding-left: 10px;
}

.about {
  img:not(.logo) {
    width: 100% !important;
  }
}

.icons-grid img {
  width: 100%;
}

svg.icon {
  width: 1em;
  height: 1em;
}

[class*="-before"] svg.icon {
  margin-left: 0.25em;
  margin-right: 0.75em;
}

[class*="-after"] svg.icon {
  margin-left: 0.75em;
}

.feature-card {
  padding: 0 8px;
}

.product-hero {
  .inner {
    padding: 0.75rem 1.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .pill-button {
    margin: 0 auto;
  }
}

@media (min-width: 568px) {
  .product-hero {
    .inner {
      padding: 4rem 1.5rem;
    }
  }
}

.icon-pack-grid {
  > figure {
    width: 33.333333%;
    padding: 0.5rem;
    text-align: center;
  }

  img {
    max-width: 128px;
    width: 100%;
    height: auto;
  }
}

@media (min-width: 480px) {
  .file-format {
    img {
      display: block;
      margin: 0 auto;
    }
  }
}

// PureCSS sm
@media (min-width: 568px) {
  .subscribe-form {
    .form-group {
      gap: 0;
    }

    .formkit-field {
      padding-right: 1rem;
    }
  }

  .icon-pack-grid {
    > figure {
      width: 25%;
    }
  }
}

// PureCSS md
@media (min-width: 768px) {
  .icon-pack-grid {
    > figure {
      width: 20%;
    }
  }
}

// PureCSS lg
@media (min-width: 1024px) {
  .icon-pack-grid {
    > figure {
      width: 16.6667%;
    }
  }

  .product-hero {
    .pill-button {
      margin: unset;
    }
  }
}
