.pill-button {
  display: flex;
  width: max-content;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--color-blue);
  transition: background-color 0.3s linear !important;
  text-decoration: none;

  &:hover {
    background-color: #000;
    text-decoration: none;
  }

  border: none !important;
}

//:not(#top-nav),
:not(.masthead),
#top-nav:not(.expanded) {
  .pill-button {
    color: #fff !important;
    border-radius: 3em;
    border: none !important;
    @include button-size(
      $btn-padding-y-lg,
      $btn-padding-y-lg * 2,
      $btn-font-size,
      $btn-line-height,
      3rem
    );
    border-radius: 3em !important;
  }

  .pill-button.btn-dark {
    color: #fff !important;
  }

  transition: background-color 0.3s linear; // !important;
}

.btn-xl {
  padding: 1rem 3rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-light {
  color: #212529;
  background-color: var(--color-gray-100);
  border-color: var(--color-gray-100);
}

.btn-dark {
  color: #fff;
  background-color: var(--color-gray-dark);
  border-color: var(--color-gray-dark);
}

.btn,
button,
.button,
.btn-light,
.btn-dark {
  :focus {
    border: none !important;
    box-shadow: none !important;
  }
}

.btn,
button,
.button {
  font-weight: 400;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none !important;
}

// .cart-icon-after::after {
//   display: block;
//   content: "";
//   width: 19px;
//   height: 17px;
//   margin-left: 6px;
//   background: transparent url(../icons/shopping-cart.svg) no-repeat center;
// }

// .cart-icon-before::before {
//   display: block;
//   content: "";
//   width: 19px;
//   height: 17px;
//   margin-right: 1rem;
//   background: transparent url(../icons/shopping-cart.svg) no-repeat center;
// }

// .download-icon-before::before {
//   display: block;
//   content: "";
//   width: 19px;
//   height: 17px;
//   margin-right: 1rem;
//   background: transparent url(../icons/download.svg) no-repeat center;
// }
