.homepage-hero {
  background-color: #86d9ea;
  background-image: linear-gradient(
    135deg,
    #b1e9e6,
    #b1e9e6 0,
    #3abdf1 100%,
    #3abdf1 0
  );

  .pill-button {
    max-width: 300px;
    width: 90%;
  }
}

.about-hero {
  img {
    max-width: 100%;
  }
}

.jumbotron {
  border-radius: 0 !important;
  padding: 0 15px;
  background-color: var(--color-hero-bg);
}

.jumbotron.minimal {
  min-height: 78px;
}

.hero-action-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem 1rem;

  a {
    margin: 0;
  }
}

@media (min-width: 1024px) {
  .hero-action-row {
    gap: 1.5rem 2rem;
  }
}
