/*
 * Scaling follows tailwind - 0.25rem increments (or 4px, with base size 1rem = 16px)
 * Unable to get Tailwind to work with Sass, tried with Tailwindcss as postcss plugin
 * PureCSS has no such utility
 */

// *** PADDING

.pt-20 {
  padding-top: 5rem !important;
}

.pt-16 {
  padding-top: 4rem !important;
}

.pt-8 {
  padding-top: 2rem !important;
}

.pt-6 {
  padding-top: 1.5rem !important;
}

.pt-4 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pb-4 {
  padding-bottom: 1rem !important;
}

.pb-6 {
  padding-bottom: 1.5rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pl-4 {
  padding-left: 1rem !important;
}

.pr-4 {
  padding-right: 1rem !important;
}

.pl-6 {
  padding-left: 1.5rem !important;
}

.pr-6 {
  padding-right: 1.5rem !important;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.py-8 {
  // root_unit = 4px
  // 32px = root_unit * 8
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.px-6 {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

// 32px
// 16px

// *** MARGIN

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}


.mt-6 {
  margin-top: 1.5rem !important;
}

.mt-8 {
  margin-top: 2rem !important;
}

.mt-10 {
  margin-top: 3rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mt-6 {
  margin-top: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-4 {
  margin-bottom: 1rem !important;
}

.mb-6 {
  margin-bottom: 1.5rem !important;
}

.mb-9 {
  margin-bottom: 3rem !important;
}


.mb-10 {
  margin-bottom: 2.5rem !important;
}

.mb-14 {
  margin-bottom: 3.5rem !important;
}

.mb-16 {
  margin-bottom: 4rem !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

// *** FLEX

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

.flex-row {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

// *** TEXT

.text-center {
  text-align: center;
}

.lead {
  font-size: 1.25rem;
}

// *** CONTAINER

.container {
  width: 100%;
  max-width: var(--container-max-sm);
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 720px) {
  .container {
    max-width: var(--container-max-md);
  }
}

@media (min-width: 960px) {
  .container {
    max-width: var(--container-max-lg);
  }

  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .px-lg-4 {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .px-lg-8 {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1140px) {
  .container {
    max-width: var(--container-max-xl);
  }
}

// *** IMAGES

.img-fluid {
  max-width: 100%;
  height: auto;
}

// *** TEXT

.text-white {
  color: #fff;
}
