form[name="humanistavatars-contact"] {
  textarea {
    resize: vertical;
  }

  label {
    display: block;
    font-size: 0.8rem;
    margin-top: 2rem;
  }

  textarea,
  input {
    background-color: #f5f8fa;
    display: inline-block;
    width: 90%;
    max-width: 500px;
    padding: 9px 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    color: #33475b;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;
    border-radius: 3px;
  }

  input {
    height: 40px;
  }

  button {
    margin: 1.5rem 0;
  }
}

label span.error {
  color: red;
  padding-left: 10px;
}
