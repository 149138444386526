.masthead {
  position: fixed;
  z-index: 9;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  background-color: transparent;
  transition: background-color 0.5s linear;
}

.masthead.menu-expanded {
  background-color: #fff;
}

.masthead.scrolled {
  background-color: #fff;
  box-shadow: 0 2px 3px rgba(57, 63, 72, 0.3) !important;
}

.navbar-brand {
  margin: 5px 0;
}

.no-banner .masthead {
  border-bottom: 1px solid #ccc;
}

@media (min-width: 992px) {
}
