.main-navbar {
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: -200vw;
  right: auto;
  opacity: 0;
  width: 100%;
  overflow: auto;
  max-height: calc(100vh - 77px);
  max-width: 100%;
  transition: opacity 0.25s linear;

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    inset: 0 0 auto 0;
    background-color: #fff;
    align-items: center;
    gap: 0;
    margin: 0;
  }

  a {
    display: flex;
    text-decoration: none;
    color: #000;
    padding: 8px;
    white-space: nowrap;
  }

  .pill-button {
    margin-left: 2rem;
    border-radius: 0;
    background-color: transparent !important;

    a {
      padding: 0;
    }
  }

  li {
    margin: 0;
  }

  .has-children {
    position: relative;

    a {
      display: flex;
      align-items: center;
      gap: 0 4px;
    }

    > a::after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid #000;
      transition: transform 0.15s linear 0.2s;
    }
  }

  a[aria-expanded="true"]::after {
    transform: scaleY(-1);
  }

  a[aria-expanded="true"] + .has-parent {
    height: auto;
    opacity: 1;

    a {
      padding-left: 2rem;
    }

    li {
      border: 0;
    }
  }

  .has-parent {
    // position: absolute;
    // top: 100%;
    // right: auto;
    // z-index: 1;
    display: flex;
    height: 0;
    opacity: 0;
    flex-flow: column;
    min-width: 10rem;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    overflow: hidden;
    transition: opacity 0.35s ease-in-out !important;

    li {
      width: 100%;
    }
  }

  a[aria-expanded="false"] + .has-parent {
    opacity: 0;
    left: -999999px;
    transition: opacity 0.35s ease-in-out !important;
  }

  a[aria-expanded="true"] + .has-parent {
    opacity: 1;
    left: auto;
  }

  .has-parent a {
    padding: 5px 24px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.3s linear;

    &:hover {
      color: #16181b;
      text-decoration: none;
      background-color: #f8f9fa;
    }
  }

  .has-parent > li:last-child a {
    border: 0;
  }

  .pill-button {
    background-color: #007bff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 9px;
    padding: 0.5rem 1.5rem !important;
    margin-left: 1.5rem;

    a {
      color: #fff;
    }
  }
}

.prepend-dollar-sign::before {
  content: "";
  display: block;
  background: url(../icons/dollar-sign.svg) no-repeat center;
  width: 8px;
  height: 14px;
}

.navbar-toggler {
  background-color: transparent;

  svg path {
    stroke: var(--color-text);
  }
}

.home .navbar-toggler svg path {
  stroke: #fff;
}

.scrolled .navbar-toggler,
.menu-expanded .navbar-toggler {
  svg path {
    stroke: #404040;
  }
}

.menu-expanded .main-navbar {
  left: 0;
  opacity: 1;
  padding: 0 1rem;
  box-shadow: 0 4px 4px #0001;

  ul a {
  }

  li {
    box-sizing: border-box;
    width: 100%;
    margin: 0 1rem;
    border-bottom: 1px solid $navitem-border-color;
  }

  ul a {
    padding: 20px 0;
  }

  .pill-button {
    padding: 0 !important;
    justify-content: flex-start;

    &.prepend-dollar-sign::before {
      filter: invert(100%);
    }

    a {
      color: initial;
    }
  }
}

@media (min-width: 992px) {
  .main-navbar {
    position: static;
    opacity: 1;
    width: auto;
    overflow: visible;
    background-color: unset;

    ul {
      flex-direction: row;
      background-color: unset;
    }

    li {
      border-bottom: 0;
    }

    ul a {
      padding: 8px;
    }

    .has-parent {
      position: absolute;
      top: 100%;
      right: auto;
      z-index: 1;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }

    .pill-button {
      border-radius: 50px;
      background-color: var(--color-blue) !important;
    }
  }

  .navbar-toggler {
    display: none;
    inset: auto auto;
  }
}
