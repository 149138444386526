.page-footer {
  padding: 1.5rem;
  margin-top: 0 !important;
  background-color: var(--color-gray-dark);

  a {
    color: #00bbec;
  }

  a:hover {
    text-decoration: none;
    color: #007ea0;
  }

  nav {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .nav-link {
    display: block;
    padding: 0.5rem;
  }
}

.footer-copyright {
  color: #777;
  margin-top: 1rem;
}

.to-top {
  opacity: 0;
  position: fixed;
  inset: auto 1rem 0.5rem auto; // shorthand for: top right bottom left
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222; // url(../icons/chevron-up-solid.svg) no-repeat center;
  background-size: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  border: 2px solid transparent;
  border-radius: 50%;
  color: transparent;
  user-select: none;
  cursor: pointer;
  transition-delay: 0s, 0.25s !important;
  transition-property: opacity, inset !important;
  transition-duration: 0.25s !important;

  &:hover {
    border-color: #fff3;
    background-color: #000;
  }

  &.show {
    left: auto;
    opacity: 0.5;
    transition-delay: 0.25s, 0s !important;
    transition-property: opacity, inset !important;
    transition-duration: 0.25s !important;
  }

  &:active {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .page-footer {
    .nav-link {
      display: block;
      padding: 0.5rem 1rem;
    }
  }

  .to-top {
    inset: auto 150vw 2.5rem auto;

    &.show {
      right: 1.5rem;
    }
  }
}
